import teamImg1 from "../../assets/team-1.jpg";
import teamImg2 from "../../assets/team-2.jpg";
import teamImg3 from "../../assets/team-3.jpg";
export const teams = [
  {
    name: "Walter White",
    img: teamImg1,
    subTitle: "Chief Executive Officer",
    text: "Magni qui quod omnis unde et eos fuga et exercitationem. Odio veritatis perspiciatis quaerat qui",
  },
  {
    name: "Sarah Jhinson",
    img: teamImg2,
    subTitle: "Product Manager",
    text: "Repellat fugiat adipisci nemo illum nesciunt voluptas repellendus. In architecto rerum rerum temporibus",
  },
  {
    name: "William Anderson",
    img: teamImg3,
    subTitle: "CTO",
    text: "Voluptas necessitatibus occaecati quia. Earum totam consequuntur qui porro et laborum toro des clara",
  },
];
