import sponser1 from "../../assets/client-1.png";
import sponser2 from "../../assets/client-2.png";
import sponser3 from "../../assets/client-3.png";
import sponser4 from "../../assets/client-4.png";
import sponser5 from "../../assets/client-5.png";
import sponser6 from "../../assets/client-6.png";
export const paragraphs = [
  "Ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  "Duis aute irure dolor in reprehenderit in voluptate velit.",
  "Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.",
];
export const cards = [
  {
    title: "Lorem Ipsum",
    text: "Ulamco laboris nisi ut aliquip ex ea commodo consequat. Et consectetur ducimus vero placeat",
    number: "01",
  },
  {
    title: "Repellat Nihil",
    text: "Dolorem est fugiat occaecati voluptate velit esse. Dicta veritatis dolor quod et vel dire leno para dest",
    number: "02",
  },
  {
    title: "Ad ad velit qui",
    text: "Molestiae officiis omnis illo asperiores. Aut doloribus vitae sunt debitis quo vel nam quis",
    number: "03",
  },
];
export const sponsors = [
  sponser1,
  sponser2,
  sponser3,
  sponser4,
  sponser5,
  sponser6,
];
